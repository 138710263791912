import React, { useEffect, useRef, useState } from 'react';

import styles from './customerStories.module.scss';

// MUI
import {
  Typography,
  Grid,
  Link,
  Stack,
  Box,
  CardMedia,
  Container,
} from '@mui/material';

import BaseCarousel from '../../../../commons/components/ui/mui5/BaseCarousel/BaseCarousel';
import DoubleArrowButtons from '../../../../commons/components/ui/DoubleArrowButtons/DoubleArrowButtons';
import { useResponsive } from '../../../../commons/hooks';
import ContainedButton from '../../../../commons/hoc/Buttons/ContainedButton/ContainedButton';

import {
  rfPainRelief,
  rfZenFactor,
  rfZFactor,
  rfGoFactor,
  testimonialsReliefImg,
  testimonialsZenImg,
  testimonialsGoImg,
  testimonialszImg,
} from '../../../../src/images';
import Image from 'next/image';
import imgLoader from '../../../../imgloader';
import { ProductsCategory } from '../../../../config';
import { useRouter } from 'next/router';

const miniProdCardClassMap = {
  [ProductsCategory.RELIEF_FACTOR]: styles.rel,
  [ProductsCategory.GO_FACTOR]: styles.gof,
  [ProductsCategory.ZEN_FACTOR]: styles.zen,
  [ProductsCategory.Z_FACTOR]: styles.zfa,
};
const CarouselContentComponent = ({ content, id = '' }) => {
  const isTabletOrMobile = useResponsive('only', 'lg');
  const router = useRouter();

  return (
    <Stack
      alignItems="baseline"
      className={styles.storiesContentMainContainer}
      id={id}
    >
      <Box className={styles.storiesContent}>
        <Typography
          variant="h4"
          color="primary.main"
          sx={{
            pr: { lg: 1, xs: 1, xl: 18 },
            my: { lg: 3, md: 1, sm: 1, xs: 1 },
            textAlign: 'left',
          }}
        >
          {`"${content.review}"`}
        </Typography>
        <Typography variant="h4" color="primary.main">
          {content.name}
        </Typography>
        <Typography variant="h5" color="primary.black">
          {content.duration}
        </Typography>
      </Box>
      <Stack
        className={styles.miniProdCardContainer}
        sx={{
          my: { lg: 5, md: 2, sm: 5, xs: 5 },
          ml: 1,
          width: isTabletOrMobile
            ? 353
            : { lg: 438, md: '95%', sm: '94%', xs: '94%' },
        }}
      >
        <Stack
          className={`${styles.miniProdCardContent} ${
            miniProdCardClassMap[content.product]
          }`}
          sx={{
            py: { lg: 2, md: 1.5, sm: 1.5, xs: 1.5 },
            px: { lg: 3, md: 2, sm: 2, xs: 1 },
          }}
        >
          <div className={styles.miniProdCardProduct}>
            <CardMedia
              component="img"
              className={styles.miniCartImg}
              image={content.productImg}
              alt=""
            />
            <Stack
              display="flex"
              direction="column"
              alignItems="left"
              justifyContent="center"
              sx={{ pl: { lg: 3, sm: 1, xs: 1 }, textAlign: 'left' }}
            >
              <Typography variant="p2">{content.category}</Typography>
              <Typography variant="h4" color="primary.main">
                {content.productName}
              </Typography>
            </Stack>
          </div>
          <div className={styles.miniProdCardBtnContainer}>
            <ContainedButton
              style={{ height: '35px' }}
              onClick={() => {
                if (router.pathname == '/') {
                  router.push(content.link);
                } else {
                  router.push(
                    {
                      pathname: content.link,
                      hash: 'buy',
                    },
                    undefined,
                    { scroll: false }
                  );
                }
              }}
              btnType="dark"
            >
              Shop Now
            </ContainedButton>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

const CarouselMobNavButton = ({ items, onClick, active }) => {
  return (
    <>
      {items.map((item, i) => (
        <button
          key={i}
          className={`${styles.customerStoriesMobNaBtn} ${
            active == i && styles.btnActive
          }`}
          onClick={() => onClick(i)}
        ></button>
      ))}
    </>
  );
};

const CarouselImageComponent = ({ content, id = '' }) => {
  return (
    <div className={styles.customerStoryImgContainer}>
      <Image
        src={content.testimonialImg}
        layout="fill"
        loader={imgLoader}
        alt=""
        loading="eager"
        className={styles.customerStoryImg}
      />
    </div>
  );
};
export default function CustomerStories({
  productType = 'ALL',
  hasBackgroundColor = true,
}) {
  const baseCarouselRef = useRef(null);
  const imageCarouselRef = useRef(null);
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const isMobile = useResponsive('down', 'md');

  const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: isMobile,
    infiniteLoop: false,
    showThumbs: false,
    useKeyboardArrows: true,
    stopOnHover: true,
    dynamicHeight: false,
    emulateTouch: true,
    autoFocus: false,
    thumbWidth: 100,
    interval: 2000,
    transitionTime: 500,
    swipeScrollTolerance: 5,
    preventMovementUntilSwipeScrollTolerance: true,
  });
  const customerStoriesContent = [
    {
      id: 1,
      review:
        "When you have pain for 6 years and start taking Relief Factor and start feeling pain free you can't believe it.",
      name: ' - Art D.',
      //duration: 'Relief Factor User, 3 Years',
      productImg: rfPainRelief,
      category: 'Pain Relief',
      productName: 'Relief Factor',
      link: '/products/relief-factor',
      gradient: '#9cd4ea87',
      testimonialImg: testimonialsReliefImg,
      product: ProductsCategory.RELIEF_FACTOR,
    },
    {
      id: 2,
      review: 'I absolutely love Zen Factor. I tell all my friends about it.',
      name: '- Drew M.',
      //duration: 'Relief Factor User, 3 Years',
      productImg: rfZenFactor,
      category: 'Stress Response',
      productName: 'Zen Factor',
      link: '/products/zen-factor',
      gradient: '#5d4d9a5e',
      testimonialImg: testimonialsZenImg,
      product: ProductsCategory.ZEN_FACTOR,
    },
    {
      id: 3,
      review:
        'Go Factor is one of your more phenomenal products, when you take it, you instantly know you took it! Thank you for having such a great product and a good company.',
      name: '- Melonie M.',
      // duration: 'Relief Factor User, 3 Years',
      productImg: rfGoFactor,
      category: 'Energy Boost',
      productName: 'Go Factor',
      link: '/products/go-factor',
      gradient: '#50723473',
      testimonialImg: testimonialsGoImg,
      product: ProductsCategory.GO_FACTOR,
    },
    {
      id: 4,
      review:
        'I just had to share how thankful I am for your product. I was unable to I get a full nights sleep for over a year!! But now after taking your product, I can sleep!! It took a full month to work for me but thank God it works! Thank you!!!',
      name: '- Brenda M.',
      // duration: 'Relief Factor User, 3 Years',
      productImg: rfZFactor,
      category: 'Sleep Support',
      productName: 'Z Factor',
      link: '/products/z-factor',
      gradient: '#1f4e815e',
      testimonialImg: testimonialszImg,
      product: ProductsCategory.Z_FACTOR,
    },
  ];

  const next = () => {
    if (baseCarouselRef.current) baseCarouselRef.current.handleNext();
    if (imageCarouselRef.current) imageCarouselRef.current.handleNext();
  };

  const prev = () => {
    if (baseCarouselRef.current) baseCarouselRef.current.handlePrev();
    if (imageCarouselRef.current) imageCarouselRef.current.handlePrev();
  };

  const handleSlideChange = (index) => setCurrentSlideIdx(index);

  const productCustomerStoriesContent = customerStoriesContent.filter(
    (i) => i.product == productType
  );

  const slides =
    productType == 'ALL'
      ? customerStoriesContent.map((item) => {
          return {
            key: item.id,
            component: <CarouselContentComponent content={item} />,
          };
        })
      : productCustomerStoriesContent.map((item) => {
          return {
            key: item.id,
            component: <CarouselContentComponent content={item} />,
          };
        });
  const imgSlides =
    productType == 'ALL'
      ? customerStoriesContent.map((item) => {
          return {
            key: item.id,
            component: <CarouselImageComponent content={item} />,
          };
        })
      : productCustomerStoriesContent.map((item) => {
          return {
            key: item.id,
            component: <CarouselImageComponent content={item} />,
          };
        });

  useEffect(() => {
    if (baseCarouselRef.current.currentSlide != currentSlideIdx) {
      baseCarouselRef.current.updateCurrentSlide(
        imageCarouselRef.current.currentSlide
      );
      setCurrentSlideIdx(imageCarouselRef.current.currentSlide);
    } else if (imageCarouselRef.current.currentSlide != currentSlideIdx) {
      imageCarouselRef.current.updateCurrentSlide(
        baseCarouselRef.current.currentSlide
      );
      setCurrentSlideIdx(baseCarouselRef.current.currentSlide);
    }
  }, [imageCarouselRef.current, baseCarouselRef.current]);

  const btnClick = (i) => {
    if (i > currentSlideIdx) {
      next();
    } else {
      prev();
    }
  };

  return (
    <div
      className={
        hasBackgroundColor ? styles.customerStoriesBg : styles.customerStories
      }
      id="testimonials"
    >
      <Container
        sx={{
          maxWidth: { xl: 'xl', lg: '960px', md: '90%', sm: '90%' },
        }}
      >
        <Grid container display="flex" className={styles.storiesMainContent}>
          <Grid
            item
            xs
            width={{ xl: 580, md: 328, sm: '100%', xs: '100%' }}
            sx={{ px: { xs: 1, sm: 0 } }}
          >
            <BaseCarousel
              handleSlideChange={handleSlideChange}
              ref={imageCarouselRef}
              items={imgSlides}
              options={getConfigurableProps()}
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              px: { lg: 8, md: 0 },
              pl: { md: 3 },
              margin: { xs: 'auto' },
            }}
            width={{ xl: 580, md: 327, sm: 338, xs: 305 }}
            className={styles.storiesContentMain}
          >
            <Stack display="flex" sx={{ pl: { lg: 6 } }}>
              <Typography
                sx={{ ml: 1 }}
                variant="h5"
                color="primary.black"
                className={styles.storiesSubTitle}
              >
                Stories From People Like You
              </Typography>
              <Box className={styles.carouselContent}>
                <BaseCarousel
                  handleSlideChange={handleSlideChange}
                  ref={baseCarouselRef}
                  items={slides}
                  options={getConfigurableProps()}
                  className={styles.carouselHeight}
                ></BaseCarousel>
              </Box>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="start"
                sx={{
                  ml: 1,
                }}
                className={styles.customerStoriesNavBtn}
              >
                {productType == 'ALL' ? (
                  <DoubleArrowButtons
                    prevBtnHandler={prev}
                    nextBtnHandler={next}
                    nextDisabled={
                      currentSlideIdx == customerStoriesContent.length - 1
                    }
                    prevDisabled={currentSlideIdx == 0}
                  />
                ) : (
                  productCustomerStoriesContent.length > 2 && (
                    <DoubleArrowButtons
                      prevBtnHandler={prev}
                      nextBtnHandler={next}
                      nextDisabled={
                        currentSlideIdx ==
                        productCustomerStoriesContent.length - 1
                      }
                      prevDisabled={currentSlideIdx == 0}
                    />
                  )
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="start"
                sx={{}}
                className={styles.customerStoriesMobNavBtn}
              >
                {productType == 'ALL' ? (
                  <CarouselMobNavButton
                    items={customerStoriesContent}
                    onClick={btnClick}
                    active={currentSlideIdx}
                  />
                ) : (
                  productCustomerStoriesContent.length > 2 && (
                    <CarouselMobNavButton
                      items={customerStoriesContent}
                      onClick={btnClick}
                      active={currentSlideIdx}
                    />
                  )
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
